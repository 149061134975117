<template>
  <PrimaryModal :show="show" :title="title" @close="closeModal">
    <slot></slot>
    <v-card-text>
      <div class="d-flex justify-center">
        <v-btn
          class="flex-grow-1 mr-4 btn01"
          outlined
          @click="closeModal"
          color="#FF7D00"
        >
          取消
        </v-btn>
        <v-btn
          depressed
          @click="confirmModal"
          class="flex-grow-1 btn02"
          :loading="loading"
          dark
          color="#FF7D00"
        >
          確認
        </v-btn>
      </div>
    </v-card-text>
  </PrimaryModal>
</template>

<script>
import PrimaryModal from "components/modal/primaryModal.vue";

export default {
  components: {
    PrimaryModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirmModal() {
      this.$emit("confirm");
    },
  },
};
</script>
